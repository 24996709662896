import React from 'react'
import { Card, Container } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"

export const CompletedContainer = () => {
    return (
        <Container>
            <Card>
                <Card.Body>
                    メッセージを送信しました！<br/>
                    回答者のSNSをフォローして、回答を待ちましょう！
                </Card.Body>
            </Card>
        </Container>
    )
}
