import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import styled from 'styled-components'

export const NotFound = () => {
    return (
        <Main className="d-flex justify-content-center align-items-center" id="main">
            <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
            <div className="inline-block align-middle">
    	    <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
            </div>
        </Main>
    )
}

const Main = styled.div`
    height: 100vh;
`
