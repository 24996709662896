import * as React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Top, User, NotFound } from "./containers/"

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Top} /> 
        <Route path="/users/:uid/ask" component={User} />
        <Route path='/privacy-policy' component={ () => { 
          window.location.href = 'https://noppelab.com/vear-web/PrivacyPolicy'
          return null
        }}/>
        <Route path='/terms-of-use' component={ () => { 
          window.location.href = 'https://noppelab.com/vear-web/TermsOfUse'
          return null
        }}/>
        <Route path="/faq" component= { () => {
          window.location.href = 'https://note.com/noppefoxwolf/n/n84557c34ebe5'
          return null 
        }}/>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router