import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { firestore } from '../plugins/firebase'
import firebase from 'firebase'
import 'firebase/firestore'

export enum RequestStatus {
    Pending,
    Fulfulled,
    Rejected
}

type State = {
    uid: string
    username: string
    description: string
    profileImageURL: string
    requestStatus: RequestStatus
}

const initialState: State = {
    uid: "",
    username: "",
    description: "",
    profileImageURL: "",
    requestStatus: RequestStatus.Pending
}

interface Response {
    uid: string
    username: string
    description: string
    profileImageURL: string
}

export const fetchUserByUID = createAsyncThunk<Response, { uid: string }>("users/fetchUserByUID", async ({ uid }, thunkApi) => {
    const promise = firestore
        .collection("public/v1/users")
        .doc(uid)
        .get()
        .then((snapshot) => {
            var user = snapshot.data() as firebase.firestore.DocumentData;
            return {
                uid: uid,
                username: user.username,
                description: user.description,
                profileImageURL: user.profileImageURL,
            }
        }).catch(err => {
            return thunkApi.rejectWithValue(err.response.data)
        })
    const data = await promise
    return data
})

export const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserByUID.fulfilled, (state, action) => {
            state.requestStatus = RequestStatus.Fulfulled
            state.username = action.payload.username
            state.description = action.payload.description
            state.profileImageURL = action.payload.profileImageURL
            state.uid = action.payload.uid
        })
        builder.addCase(fetchUserByUID.rejected, (state, action) => {
            state.requestStatus = RequestStatus.Rejected
        })
    }
})