import * as React from 'react'
import { Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { showModal, changeText } from "../modules/messagesModule"
import { RootState } from '../rootReducer'
import styled from 'styled-components'

export const MessageForm = () => {
    const usersSelector = useSelector((state: RootState) => state.users)
    const dispatch = useDispatch()
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(showModal())
    }

    return (
        <Card>
            <Card.Body>
                <Form onSubmit={onSubmit}>
                    <ProfileImage width={30} height={30} src={usersSelector.profileImageURL} />
                    <Form.Label>{usersSelector.username} さんに質問をおくる</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="面白いゲームを教えてください！" onChange={ e => { dispatch(changeText(e.target.value)) } } />
                    <div className="d-flex justify-content-end">
                        <TextCountLabel/>
                    </div>
                    <div className="d-flex justify-content-center">
                            <SubmitButton />
                    </div>
                    <>
                        <small>ネガティブな内容、性的な内容、スパムは自動的に削除されることがあります。</small>
                    </>
                </Form>
            </Card.Body>
        </Card>
    )
}

const ProfileImage = styled.img`
    margin: 6px;
`

const SubmitButton = () => {
    const messagesSelector = useSelector((state: RootState) => state.messages)
    return (
        <Button type="submit" disabled={!messagesSelector.isValidText}>
            おくる
        </Button>
    )
}

const TextCountLabel = () => {
    const messagesSelector = useSelector((state: RootState) => state.messages)

    return (
        <p>{messagesSelector.text.length}/140</p>
    )
}