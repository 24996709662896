import React from 'react';
import { Container } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Footer = () => {
    return (
        <Container>
            <Links className="d-flex align-content-start flex-wrap mt-3">
                <Link to="/terms-of-use" className="mr-1">利用規約</Link>
                <Link to="/privacy-policy" className="mr-1">プライバシーポリシー</Link>
                <Link to="/faq" className="mr-1">よくある質問</Link>
            </Links>
            <small>©︎noppelab</small>
        </Container>
    )
}

const Links = styled.div`
    font-size: 12px;
`