import { combineReducers } from "@reduxjs/toolkit"
import { usersSlice, messagesSlice } from "./modules/"

// rootReducer の準備
const rootReducer = combineReducers({
    users: usersSlice.reducer,
    messages: messagesSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer