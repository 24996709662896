
import * as React from 'react'

const linkStyle: React.CSSProperties = {
    display: "inline-block",
    overflow: "hidden",
    borderRadius: "13px",
    width: "125px",
    height: "42px"
}
const imgStyle: React.CSSProperties = {
    borderRadius: "13px",
    width: "125px",
    height: "42px"
}

export const AppStoreBanner = () => {
    return (
        <a href="https://apps.apple.com/us/app/vear-anime-avatar-camera/id1490697369?itsct=apps_box&amp;itscg=30200" style={linkStyle}>
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/ja-JP?size=250x83&amp;releaseDate=1577664000&h=994a1b16df382ad6d6d87abdc1350d11" alt="Download on the App Store" style={imgStyle} />
        </a>
    )
}