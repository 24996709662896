import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../plugins/firebase'

type State = {
    text: string
    isValidText: boolean
    isModalPresented: boolean
    isPostCompleted: boolean
}

const initialState: State = {
    text: "",
    isValidText: false,
    isModalPresented: false,
    isPostCompleted: false,
}

interface PostMessageResponse {
    success: boolean
}

export const postMessage = createAsyncThunk<PostMessageResponse, { text: string, uid: string }>("messages/postMessage", async ({ text, uid }, thunkApi) => {
    const doc = {
        text: text,
        createdAt: new Date()
    }
    const path = `private/v1/users/${uid}/messages`
    const promise = firestore
        .collection(path)
        .add(doc)
        .then((snapshot) => {
            return {
                success: true
            }
        }).catch(err => {
            return thunkApi.rejectWithValue(err.response.data)
        })
    const data = await promise
    return data
})

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        changeText(state, action: PayloadAction<string>) {
            state.text = action.payload
            state.isValidText = between(action.payload.length, 1, 140)
        },
        showModal(state, action: PayloadAction<void>) {
            state.isModalPresented = true
        },
        hideModal(state, action: PayloadAction<void>) {
            state.isModalPresented = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postMessage.fulfilled, (state, action) => {
            state.isPostCompleted = true
        })
        builder.addCase(postMessage.rejected, (state, action) => {
            // state.requestState = RequestStatus.Fulfulled
        })
        builder.addCase(postMessage.pending, (state, action) => {
            // state.requestState = RequestStatus.Pending
        })
    }
})

function between(x: number, min: number, max: number) {
    return x >= min && x <= max;
}

export const { changeText, showModal, hideModal } = messagesSlice.actions