import firebase from 'firebase/app'
import 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCnLIhgDodYNiq0CYGv9_SmD04tLrRwcoA",
    authDomain: "vear-19523.firebaseapp.com",
    databaseURL: "https://vear-19523.firebaseio.com",
    projectId: "vear-19523",
    storageBucket: "vear-19523.appspot.com",
    messagingSenderId: "826214213629",
    appId: "1:826214213629:web:203f90a5eeedd635ec5b94",
    measurementId: "G-50VSE5PN6G"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
export const firestore = firebaseApp.firestore()