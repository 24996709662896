import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { match, Redirect } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import { RootState } from "../rootReducer"
import { Loading, ConfirmModal, Header, Footer, MessageForm, CompletedContainer, AppStoreBanner } from "../components"
import { fetchUserByUID, RequestStatus as UserRequestStatus } from "../modules"
import { Container } from 'react-bootstrap'

interface UserParams {
    uid: string
}

interface UsersProps {
    required: string
    match: match<UserParams>
}

export const User = (props: UsersProps) => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        document.title = "TOTE（トート） - バーチャル専用質問ポスト"
        dispatch(fetchUserByUID({ uid: props.match.params.uid }))
    }, [dispatch, props.match])
    
    return (
        <>
            <Header />
            <Container className="mt-3">
                <MessageFormContainer/>
            </Container>
            <Container className="mt-3 d-flex justify-content-center">
                <small>質問はvearのアプリ内から募集することができます</small>
            </Container>
            <Container className="mt-3 d-flex justify-content-center">
                <AppStoreBanner/>
            </Container>
            <Footer />
            <ConfirmModal />
        </>
    )
}

const MessageFormContainer = () => {
    const usersSelector = useSelector((state: RootState) => state.users)
    const messagesSelector = useSelector((state: RootState) => state.messages)
    
    switch (usersSelector.requestStatus) {
        case UserRequestStatus.Pending:
            return (
                <Loading />
            )
        case UserRequestStatus.Fulfulled:
            if (messagesSelector.isPostCompleted) {
                return (
                    <CompletedContainer />
                )
            } else {
                return (
                    <MessageForm/ >
                )
            }
        case UserRequestStatus.Rejected:
            return (
                <Redirect to={"/404"}/>
            )
    }
}