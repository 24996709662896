import React from 'react';
import { Navbar, NavbarBrand } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";

export const Header = () => {
    return (
        <Navbar bg="dark" variant="dark">
            <NavbarBrand>TOTE（トート）</NavbarBrand>
            <Navbar.Text>バーチャル専用質問ポスト</Navbar.Text>
        </Navbar>
    )
}
