import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import { RootState } from "../rootReducer";
import { hideModal, postMessage } from "../modules";

export const ConfirmModal = () => {
    const messagesSelector = useSelector((state: RootState) => state.messages)
    const usersSelector = useSelector((state: RootState) => state.users)
    const dispath = useDispatch()
    const onSubmit = () => { 
        dispath(postMessage({ uid: usersSelector.uid, text: messagesSelector.text }))
        dispath(hideModal())
    }

    return (
        <Modal show={messagesSelector.isModalPresented} onHide={() => { dispath(hideModal()) }}>
            <Modal.Header>
                <Modal.Title>このメッセージを送りますか？</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control as="textarea" value={messagesSelector.text} disabled/>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e => { dispath(hideModal()) }}>
                    キャンセル
                </Button>
                <Button variant="primary" onClick={e => { onSubmit() }}>
                    おくる
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
